<template>
  <v-card
    flat
    :tile="tile"
    style="cursor: pointer"
    :class="tile ? '' : 'mb-1'"
    @click="expanded = !expanded"
    :ripple="false"
    >
    <v-card-text
      class="d-flex flex-row pb-0"
      >
      <v-avatar 
        @click="$router.push({ name: 'profile', params: { slug: comment.profile.slug } })"
        size="30"
        color="grey"
        >
        <v-img
          v-if="comment.profile.picture"
          :src="url + comment.profile.picture.url"
          ></v-img>
        <v-icon 
          v-else
          small
          dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>
      <div
        class="ml-2 d-flex flex-column justify-center grow py-1 px-2 rounded-lg"
        style="background-color: #f4f4f4; max-width: calc(100% - 38px)"
        >
        <div
          v-if="reported && hide"
          class="pa-2 white rounded-lg elevation-1 d-flex align-center"
          style="position: absolute; bottom: 50%; left: 50%; z-index: 40; width: fit-content; transform: translate(-50%, 50%); gap: 4px"
          >
          <v-icon
            color="error"
            >
            mdi-information
          </v-icon>

          <div
            class="body-2"
            style="width: 200px"
            >
            Reportaste este comentario.
          </div>

          <div>
            <v-btn
              outlined
              x-small
              @click="hide = false"
              >
              Ver
            </v-btn>
          </div>
        </div>
        <div
          ref="comment"
          :class="expanded ? '' : 'comment-box'"
          :style="reported && hide ? 'filter: blur(3px)' : ''"
          class="text-body-2"
          >
          <div
            class="font-weight-medium d-flex flex-row align-center"
            >
            <div
              class="black--text"
              >
              {{ comment.profile.name }}
            </div>
            <template
              v-if="comment.replyingTo"
              >
              <v-icon small>mdi-chevron-right</v-icon>
              <span>{{ comment.replyingTo }}</span>
            </template>
          </div>

          <div
            style="word-break: normal"
            >
            {{ comment.body }}
          </div>
        </div>
        <div
          class="caption grey--text text-right"
          v-if="clamp"
          >
          {{ expanded ? 'ver menos' : 'ver más' }}
        </div>
      </div>
    </v-card-text>

    <v-card-actions
      class="justify-space-between align-center pb-0"
      >
      <div
        class="d-flex flex-row align-end"
        style="gap: 4px"
        >
        <v-btn
          x-small
          text
          @click.stop="reply"
          >
          Responder
        </v-btn>
      </div>

      <div
        class="d-flex flex-row align-center"
        style="gap: 4px"
        >
        <v-btn
          x-small
          text
          @click.stop="like"
          >
          <v-icon
            small
            color="red"
            >
            {{ comment.likedBy.includes(currentUser.selectedProfile.id) ? 'mdi-heart' : 'mdi-heart-outline' }}
          </v-icon>
          {{ comment.likedBy.length }}
        </v-btn>

        <v-dialog
          v-model="deleting"
          width="600"
          >
          <v-card>
            <v-card-title>
              Eliminar comentario
            </v-card-title>
            <v-card-text>
              ¿Está seguro de que desea eliminar el comentario? Esta acción <span class="font-weight-medium red--text">no se puede deshacer</span>.
            </v-card-text>
            <v-card-actions
              class="justify-end"
              >
              <v-btn
                class="mr-2"
                text
                @click="deleting = false"
                >
                Cancelar
              </v-btn>

              <v-btn
                class="mr-2"
                color="error"
                depressed
                @click.stop="deleteComment"
                >
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-icon
          v-if="comment.profile.id == currentUser.selectedProfile.id"
          @click.stop="deleting = true"
          small
          color="red"
          >
          mdi-delete
        </v-icon>

        <report
          :comment="comment"
          @reload="reportComment"
          ></report>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DeleteComment, LikeComment } from '@/graphql/mutations/posts'
import { mapGetters } from 'vuex'

const Report = () => import('@/components/posts/comments/Report.vue')

export default {
  data () {
    return {
      expanded: false,
      deleting: false,
      clamp: false,
      reported: false,
      hide: true,
    }
  },

  props: {
    comment: {
      required: true,
      type: Object
    },
    parent: {
      required: false,
      type: Object
    },
    tile: {
      required: false,
      type: Boolean,
      default: false,
    },
    noreply: {
      required: false,
      default: false
    }
  },

  mounted () {
    this.clamp = this.$refs.comment.scrollHeight > this.$refs.comment.clientHeight

    if (this.currentUser) this.reported = this.currentUser.reportedCommentsIds.includes(this.comment.id)
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  methods: {
    like () {
      this.$apollo.mutate({
        mutation: LikeComment,
        variables: {
          input: {
            id: this.comment.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
      })
    },

    deleteComment () {
      this.$apollo.mutate({
        mutation: DeleteComment,
        variables: {
          input: {
            id: this.comment.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
      })
    },

    reply () {
      this.$emit('reply', { comment: this.comment, parent: this.parent })
    },

    reportComment () {
      this.reported = true
    }
  },

  components: {
    Report
  }
}
</script>
