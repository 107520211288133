<template>
  <v-card
    :class="$vuetify.breakpoint.mdAndDown ? 'v-dialog--fullscreen' : ''"
    >
    <v-card-title
      class="pt-3 px-4 pb-0"
      >
      Comentarios
      <v-spacer></v-spacer>

      <v-icon
        @click="$emit('close')"
        >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column flex-lg-row fill-height pa-0"
      >
      <v-card
        flat
        class="fill-height"
        style="max-height: 100vh; overflow: scroll"
        >
        <v-card-text
          id="text"
          class="fill-height d-flex flex-column justify-space-between grow"
          style="max-height: 800px; overflow-y: scroll"
          :style="$vuetify.breakpoint.lgAndUp ? 'width: 600px' : 'width: 100%'"
          >
          <div
            class="grow mb-16"
            >
            <template
              v-for="comment in comments"
              >
              <div
                class="pb-3"
                >
                <comment
                  :comment="comment"
                  :parent="comment"
                  @reload="fetchComments"
                  @reply="reply"
                  ></comment>

                <div
                  v-if="comment.children.length"
                  >
                  <div
                    class="d-flex flex-row"
                    >
                    <v-divider
                      vertical
                      class="ml-4 mr-1"
                      ></v-divider>

                    <div
                      class="grow"
                      style="max-width: calc(100% - 20px)"
                      >
                      <template
                        v-for="child in comment.children"
                        >
                        <comment
                          :comment="child"
                          :parent="comment"
                          tile
                          @reload="fetchComments"
                          @reply="reply"
                          ></comment>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div
            style="position: absolute; bottom: 0"
            class="fill-width py-2 pr-8 white"
            >
            <v-textarea
              v-model="message"
              outlined
              rows="2"
              auto-grow
              autocomplete="off"
              full-width
              append-icon="mdi-send"
              label="Agregar un comentario"
              placeholder="Agregar un comentario"
              @keyup.enter="send"
              @click:append="send"
              dense
              flat
              hide-details
              >
            </v-textarea>

            <div
              v-if="replying.id"
              style="background-color: #f4f4f4"
              class="caption font-weight-medium mt-1 py-1 d-flex justify-space-between"
              >
              <div
                class="px-2"
                >
                Respondiendo a <span class="primary--text">{{ replying.profile.name }}</span>
              </div>

              <div>
                <v-btn
                  x-small
                  @click="clearReply"
                  color="error"
                  text
                  >
                  Cancelar
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { Comments } from '@/graphql/queries/posts'
import { CreateComment } from '@/graphql/mutations/posts'
import Comment from './Comment'

export default {
  data: () => ({
    message: null,
    replying: {},
    parent: {},
    comments: []
  }),

  props: {
    idx: {
      required: true,
      type: Number | String
    },
    ids: {
      required: true,
      type: Array | Object
    },
    postId: {
      required: true,
      type: String | Number
    }
  },

  components: {
    Comment
  },

  created () {
    this.fetchComments ()

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (to.name == 'profile') {
        next()
      } else {
        this.back()

        next(false)
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },

  methods: {
    setHeight () {
      if (this.$vuetify.breakpoint.lgAndUp) {
        const height = document.getElementById('img').height
        document.getElementById('text').style.height = height + "px"
      }
    },

    send () {
      if (this.message) {
        this.$apollo.mutate({
          mutation: CreateComment,
          variables: {
            input: {
              id: this.postId,
              message: this.message,
              commentId: this.replying.id,
              parentId: this.parent.id
            }
          }
        }).then( res => {
          this.$emit('reload')
        })
      }
    },

    fetchComments () {
      this.$apollo.query({
        query: Comments,
        variables: {
          ids: this.ids,
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.comments = res.data.comments
      })
    },

    back () {
      this.$emit('close')
    },

    reply (args) {
      this.replying = args['comment']
      this.parent = args['parent']
    },

    clearReply () {
      this.replying = {}
      this.parent = {}
    }
  }
}
</script>

<style scoped>
>>> *::-webkit-scrollbar {
  display: none;
}
</style>
